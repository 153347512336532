<template>
  <div class="deviceManager">
    <div class="searchView">
      <el-input
          @focus="deviceManagerSearch"
          size="medium"
          class="search"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="query"
      >
      </el-input>


      <div class="other" @click="showOther = true">
        <!--        <svg-icon width="100%" height="100%" name="add"/>-->

        <el-popover
            popper-class="popoverView"
            placement="bottom"
            width="140"
            v-model="showOther"
        >
          <div class="otherItemView" v-for="(item) in otherList" @click="doSomeThing(item)">
            <svg-icon class="otherIcon" width="20px" height="20px" :name="item.icon"/>
            <div class="otherTitle">{{ item.title }}</div>
          </div>

          <svg-icon slot="reference" width="100%" height="100%" name="add"/>
        </el-popover>

      </div>

    </div>

    <device-manager-item @deviceTypeDetail="deviceTypeDetail" v-for="(item, index) in dataList" :key="index"
                         :data-info="item"
    >
    </device-manager-item>


    <image-preview @clickImage="deviceManagerSearch" @closePreview="closeImage" v-show="showImage" class="preview"
                   :image="image"
    ></image-preview>

  </div>
</template>

<script>
import DeviceManagerItem from '@/views/deviceManager/deviceManagerItem'
import ImagePreview from '@/components/imagePreview/imagePreview'

export default {
  name: 'deviceManager',
  components: { ImagePreview, DeviceManagerItem },
  data() {
    return {
      showImage: false,
      image: '',
      showOther: false,
      otherList: [
        { icon: 'icon-paizhao', title: '拍照', type: 'photo' },
        { icon: 'icon-pandian', title: '盘点', type: 'pandian' },
        { icon: 'icon-saomiao', title: '扫描条形码', type: 'scan' }
      ],
      query: '',
      dataList: []
    }
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateImage)
  },
  mounted() {
    this.$nextTick(() => {
      // 配置微信jssdk
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.wxConfig(corpInfo)
        } catch (e) {

        }
      }

      this.getCount()
      this.addLocalStorageEvent(this.updateImage)
      this.updateImage()
    })
  },
  methods: {
    closeImage: function() {
      this.image = ''
      this.showImage = false
      this.saveImage('')
    },
    updateImage: function() {
      this.getImage().then(res => {
        if (res.result == false) {
          this.showImage = false
          this.image = ''
          return
        }
        this.showImage = true
        this.image = res.image
      })
    },

    getCount: function() {
      let getData = () => {
        return new Promise(resolve => {
          this.$api.deviceManager.getDeviceTypeCount({}).then(res => {
            if (typeof res.data == 'undefined' || res.data == null) {
              resolve({ list: [], reslut: false })
              return
            }
            resolve({ list: res.data, reslut: false })
          })
        })
      }

      getData().then(res => {
        this.dataList = res.list
      })

    },
    deviceDetail: function(res) {
      if (this.$valueIsExist(res, 'id') == false) {
        return
      }
      let info = {
        id: res['id']
      }
      this.$push('deviceDetailManager', info)
    },
    findCode: function(config) {

      let loadData = () => {
        let getPost = {
          deleted: 0,
          deviceType: '',
          query: '',
          page: 1,
          pageSize: 30,
          order: '',
          orderType: '',
          tenantId: '',
          isTenantIdChild: 1,
          categoryId: '',
          keyword:config.res
        }
        this.$api.assetModule.getDeviceList(getPost).then(res => {
          if (res.code == 200 && res.data.length > 0) {
            let item = res.data[0]
            this.deviceDetail(item);
          } else {
            this.$message.error(`该${config.type == 'barCode' ? '条形码' : '二维码'}没有录入`)
          }
          this.loading = false

        }).catch(e => {
          this.loading = false
        })
      }

      loadData();
    },
    doSomeThing: function(item) {
      if (item.type == 'scan') {
        // 扫描二维码
        this.scanQRCode().then(res => {
          if (res.result == false) {
            this.$message.error('请扫描二维码或者条形码')
            return
          }
          this.findCode(res)
        })
      } else if (item.type == 'photo') {
        // 拍照
        this.chooseImage().then(res => {
          if (res.result == false) {
            this.showImage = false
            this.image = ''
            this.$message.error('请重新选择图片')
            this.saveImage('')
            return
          }
          this.showImage = true
          this.image = res.image
          this.saveImage(res.localID)
        })
      } else if (item.type == 'pandian') {
        /*
        * 扫描盘点 点击进入扫描盘点详情
        */
        this.$push('devicePandian', {})
      }
    },
    deviceManagerSearch: function() {
      this.$push('deviceManagerSearch', {})
    },
    deviceTypeDetail: function(res) {
      this.$push('deviceList', { type: res['type'] })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceManager {
  width: 100%;
  height: 100%;
  background-color: $background_color1;

  .searchView {
    width: 100%;
    height: 50px;

    .search {
      margin-left: 10px;
      width: calc(100% - 60px);
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;
    }

    .other {
      height: 28px;
      width: 28px;
      float: right;
      position: relative;
      top: 50%;
      right: 10px;
      transform: translate(0px, -50%);

    }

  }

}

::v-deep .search .el-input__inner {
  border-radius: 20px;
}


</style>

<style lang="scss">
@import "@styles/variables.scss";

.popoverView {
  background: $popover_dark !important;
  padding: 5px;

  .otherItemView {
    height: 28px;
    margin-bottom: 5px;

    .otherIcon {
      width: 28px;
      height: 28px;
      float: left;
      position: relative;
      top: 50%;
      transform: translate(0px, -50%)
    }

    .otherTitle {
      width: calc(100% - 28px);
      height: 28px;
      line-height: 28px;
      margin-left: 5px;
      border-bottom: 0.5px solid white;
      float: right;
      color: white;
    }

  }

}

.popoverView .popper__arrow::after {
  /* 注意：placement位置不同，下面的属性不同 */
  border-bottom-color: $popover_dark !important;
}


.preview {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

</style>
