<template>
  <div class="deviceManagerItem" @click="deviceTypeDetail">
    <div class="deviceManagerContentView">
      <div class="imageContent">
        <div class="content">
          <svg-icon width="100%" height="100%" :name="icon"/>
        </div>
      </div>
      <div class="contentMsg">
        <div class="contentTextView">
          <div class="content">
            {{ content }}
            <div class="subContent">
              {{ describeContent }}
            </div>
            <div class="subContent">
              {{ subContent }}
            </div>
          </div>
        </div>
        <div class="allowView">
          <svg-icon width="100%" height="100%" name="arrow-right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'deviceManagerItem',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    icon() {
      if (this.$valueIsExist(this.dataInfo, 'type') == false) {
        return ''
      }
      let type = this.dataInfo['type']
      if (type == 'A') {
        return 'icon-clei-24@1x'
      } else if (type == 'B') {
        return 'icon-blei-24@1x'
      } else if (type == 'C') {
        return 'icon-clei-24@1x'
      } else if (type == 'D') {
        return 'icon-dlei-24@1x'
      }
      return ''
    },
    describeContent() {
      if (this.$valueIsExist(this.dataInfo, 'type') == false) {
        return ''
      }
      let type = this.dataInfo['type']
      if (type == 'A') {
        return '有源芯片大型设备'
      } else if (type == 'B') {
        return '有源芯片一般设备'
      } else if (type == 'C') {
        return '无源芯片低值设备'
      } else if (type == 'D') {
        return '一般设备'
      }
      return ''
    },
    subContent() {
      let count = 0
      if (this.$valueIsExist(this.dataInfo, 'count')) {
        count = this.dataInfo['count']
      }
      let type = 'A'
      if (this.$valueIsExist(this.dataInfo, 'type')) {
        type = this.dataInfo['type']
      }
      return `${type}类资产共计:${count}件`
    },
    content() {
      if (this.$valueIsExist(this.dataInfo, 'type') == false) {
        return ''
      }
      let type = this.dataInfo['type']
      return `${type}类资产管理`
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    deviceTypeDetail: function() {
      this.$emit('deviceTypeDetail', this.dataInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceManagerItem {
  background-color: white;

  .deviceManagerContentView {
    width: calc(100% - 10px);
    height: 80px;
    margin-left: 10px;
    //border-bottom: 1px solid $color4;
    margin-bottom: 5px;

    .imageContent {
      float: left;
      width: 70px;
      height: 70px;
      top: 50%;
      position: relative;
      transform: translate(0px, -50%);
      //border-right: 1px solid $color4;


      .content {
        transform: translate(10px, 5px);
        width: 60px;
        height: 60px;
        border-radius: 10px;
        text-align: center;
        line-height: 92px;
        font-size: 25px;
        color: white;
      }

    }

    .contentMsg {
      float: right;
      width: calc(100% - 85px);
      height: 70px;
      top: 50%;
      position: relative;
      transform: translate(0px, -50%);

      .contentTextView {
        width: calc(100% - 80px);
        height: 100%;
        float: left;

        .subContent {
          margin-top: 3px;
          font-size: 12px;
          color: $color3;
        }

        .content {
          font-size: 18px;
          margin-left: 10px;
          position: relative;
          top: 50%;
          transform: translate(0px, -50%);
        }
      }

      .allowView {
        margin-left: 10px;
        margin-right: 10px;
        float: right;
        width: 15px;
        height: 15px;
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);
        border-radius: 5px;
        font-size: 12px;
        text-align: center;
        line-height: 25px;

      }

    }


  }
}

</style>
